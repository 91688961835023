import { useEffect } from 'react';

import { getCookie, setCookie } from '@src/utils/cookies';
import { AdCookieName, type AdCookieNameType } from './cookie-types';

// Cookie name types should be the same as parameter keys
const getURLParam = (key: AdCookieNameType) =>
  new URLSearchParams(window.location.search).get(key);

const storeAdClickIds = (value: string, cookieName: AdCookieNameType) => {
  setCookie(cookieName, value, { domain: '.trustedhealth.com' });
};

const getGclidFromCookie = () => getCookie(AdCookieName.Gclid);
const getGBraidFromCookie = () => getCookie(AdCookieName.GBraid);
const getWBraidFromCookie = () => getCookie(AdCookieName.WBraid);
const getCcuidFromCookie = () => getCookie(AdCookieName.Ccuid);
const getSourceFromCookie = () => getCookie(AdCookieName.Source);

export const getClickAdIds = () => ({
  gclid: getGclidFromCookie(),
  gbraid: getGBraidFromCookie(),
  wbraid: getWBraidFromCookie(),
  ccuid: getCcuidFromCookie(),
  source: getSourceFromCookie(),
});

export const useStoreTrackingCookies = () => {
  useEffect(() => {
    const gclid = getURLParam(AdCookieName.Gclid) ?? getGclidFromCookie();
    const gbraid = getURLParam(AdCookieName.GBraid) ?? getGBraidFromCookie();
    const wbraid = getURLParam(AdCookieName.WBraid) ?? getWBraidFromCookie();
    const ccuid = getURLParam(AdCookieName.Ccuid) ?? getCcuidFromCookie();
    const source = getURLParam(AdCookieName.Source) ?? getSourceFromCookie();

    if (gclid) {
      storeAdClickIds(gclid, AdCookieName.Gclid);
    }
    if (gbraid) {
      storeAdClickIds(gbraid, AdCookieName.GBraid);
    }
    if (wbraid) {
      storeAdClickIds(wbraid, AdCookieName.WBraid);
    }
    if (ccuid) {
      storeAdClickIds(ccuid, AdCookieName.Ccuid);
    }
    if (source) {
      storeAdClickIds(source, AdCookieName.Source);
    }
  }, []);
};
