import { ApolloProvider } from '@apollo/client';
import {
  AdapterDateFns,
  LocalizationProvider,
  ThemeProvider,
} from '@src/ui/material-ui';
import { merge } from 'lodash';
import { DefaultSeo } from 'next-seo';
import type { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Gtag } from '@src/components/gtag';
import { FeatureFlagsProvider } from '@src/contexts/feature-flags';
import { JobsPageProvider } from '@src/contexts/jobs-page';
import { NavigationProvider } from '@src/contexts/navigation-provider';
import { analytics } from '@src/lib/analytics';
import { useApollo } from '@src/lib/apollo-client';
import { ErrorBoundary } from '@src/lib/error-reporter';
import { initSentry } from '@src/lib/sentry';
import { type ComponentProps, useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import '@src/styles/index.scss';
import { AnalyticsRoutes } from '@src/components/analytics-routes';
import { useStoreTrackingCookies } from '@src/domains/foundation/tracking/cookies/tracking-cookies';
import { useRootIdentifySessionEffect } from '@src/hooks/use-root-identify-session-effect';
import { getReferringDomain } from '@src/support/document';
import { theme } from '@src/support/theme';
import { GlobalFontFamily } from '@src/ui/global-font-family';
import { CookieName, getCookie, setCookie } from '@src/utils/cookies';
import { getDomain } from '@src/utils/cookies/utils';
import AppError from './_error';

initSentry();
NProgress.configure({ showSpinner: false });

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const ONE_HOUR = 3600;

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  if ('ga' in window) {
    (window as any).ga('send', 'event', {
      eventCategory:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
      eventLabel: id,
      nonInteraction: true, // avoids affecting bounce rate.
    });
  }
}

function TrustedApp({ ...props }: AppProps) {
  useStoreTrackingCookies();
  const { pageProps, router } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [isSegmentInit, setIsSegmentInit] = useState(false);

  const canonicalUrl = `https://app.trustedhealth.com${
    router.asPath === '/' ? '' : router.asPath
  }`.split('?')[0];

  useEffect(() => {
    const { SEGMENT_WRITE_KEY } = getConfig().publicRuntimeConfig;
    if (
      !isSegmentInit &&
      typeof SEGMENT_WRITE_KEY === 'string' &&
      router.route !== '/uptime'
    ) {
      analytics.load({ writeKey: SEGMENT_WRITE_KEY });
      setIsSegmentInit(true);
    }
  }, [isSegmentInit, router.route]);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const handleStart = () => NProgress.start();
    const handleStop = () => NProgress.done();

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router.events]);

  useEffect(() => {
    const referringDomain = getCookie(CookieName.ReferringDomain);
    if (!referringDomain) {
      const domain = getReferringDomain();
      if (domain) {
        setCookie(CookieName.ReferringDomain, domain, {
          domain: getDomain(),
          maxAge: ONE_HOUR,
        });
      }
    }
  }, []);

  useEffect(() => {
    const handler = (event: Event) => {
      switch (event.type) {
        case 'mousedown':
          document.documentElement.classList.add('mouse');
          break;
        case 'keydown':
          document.documentElement.classList.remove('mouse');
          break;
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('keydown', handler);
    };
  }, []);

  // Temporary and will remove. The Mobile team is currently working on a React Native experience for this view
  useEffect(() => {
    analytics.addSourceMiddleware(({ payload, next }) => {
      const mobileCookie = getCookie(CookieName.Mobile);

      if (mobileCookie) {
        const mobileCookieObj = JSON.parse(mobileCookie);
        const mobileVersion = mobileCookieObj['Trusted-Mobile-Version'];
        const mobilePlatform =
          mobileCookieObj['Trusted-Mobile-Platform']?.split(' ');
        const appsFlyerId = mobileCookieObj['Trusted-Mobile-AppsFlyerId'];
        payload.obj.context = merge(payload.obj.context, {
          app: {
            version: mobileVersion,
          },
          device: {
            type: mobilePlatform?.[0] ?? '',
          },
          os: {
            version: mobilePlatform?.[1] ?? '',
          },
        });
        payload.obj.properties = merge(payload.obj.properties, {
          mobileAppVersion: mobileVersion,
          mobileAppOS: mobilePlatform?.[0] ?? '',
          mobileAppOSVersion: mobilePlatform?.[1] ?? '',
        });

        if (appsFlyerId) {
          payload.obj.integrations = merge(payload.obj.integrations, {
            AppsFlyer: {
              appsFlyerId: appsFlyerId,
            },
          });
        }
      }
      next(payload);
    });
  }, []);

  // TODO: check if dependency is needed
  // biome-ignore lint/correctness/useExhaustiveDependencies: router.asPath
  useEffect(() => {
    if (typeof window !== 'undefined') {
      let storage: Storage;
      try {
        storage = window.sessionStorage;
      } catch (error: unknown) {
        // sessionStorage may not be accessible but we don't need to throw an error if it isn't
        if ((error as Error).name === 'SecurityError') {
          return;
        }
        throw error;
      }
      if (!storage) return;
      // Set the previous path as the value of the current path.
      const prevPath = storage.getItem('currentPath') ?? '';
      storage.setItem('prevPath', prevPath);
      // Set the current path value by looking at the browser's location object.
      storage.setItem('currentPath', window.location.pathname);
    }
  }, [router.asPath]);

  useRootIdentifySessionEffect();

  return (
    <ErrorBoundary fallback={AppError}>
      <GlobalFontFamily>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Head key="meta">
              {/* This "maximum-scale=1, user-scalable=no" */}
              {/* Prevents React Native WebView to zoom in on our app */}
              {/* But doesn't work for browsers */}
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, shrink-to-fit=no"
              />
            </Head>
            <Head key="meta-google">
              <meta
                name="google-site-verification"
                content="xLiQf5KFu16lhLE4dmVpwVL5rIEu1FSUU3g7yhB0fvw"
              />
            </Head>
            <DefaultSeo canonical={canonicalUrl} />
            <Gtag />
            <ApolloProvider client={apolloClient}>
              <ComponentApp {...props} />
            </ApolloProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </GlobalFontFamily>
      <AnalyticsRoutes />
    </ErrorBoundary>
  );
}

const ComponentApp = ({ Component, pageProps }: AppProps) => {
  const settingPageProps = pageProps as
    | {
        // Static pages shouldn't use feature flags because they generate a
        // loader at the first render which skips important SEO information
        skipsFeatureFlagsForStaticGeneration?: boolean;
        usesJobPageProvider?: boolean;
      }
    | undefined;

  // TODO: can we refactor this to getStaticProps?
  const staticNavConfiguration = (
    Component as unknown as {
      staticNavConfiguration?: ComponentProps<typeof NavigationProvider>;
    }
  ).staticNavConfiguration;

  let renderComponent = <Component {...pageProps} />;
  if (settingPageProps?.usesJobPageProvider) {
    renderComponent = <JobsPageProvider>{renderComponent}</JobsPageProvider>;
  }

  if (!pageProps.hideNav && staticNavConfiguration) {
    renderComponent = (
      <NavigationProvider {...staticNavConfiguration}>
        {renderComponent}
      </NavigationProvider>
    );
  }

  if (!settingPageProps?.skipsFeatureFlagsForStaticGeneration) {
    renderComponent = (
      <FeatureFlagsProvider>{renderComponent}</FeatureFlagsProvider>
    );
  }

  return renderComponent;
};

// All props here will be cached for at least 60s, be careful about what you
// pass here. Also, getInitialProps is needed so we can have runtime environment
// variables, if you're changing this here, be sure to test in docker or
// staging first. normal prod builds with yarn build will not reproduce what's
// done in production.
TrustedApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default TrustedApp;
