import { curry } from 'lodash';

export const pluralize = curry<string, string, number, string>(
  (plural, singular, count) => (count === 1 ? singular : plural)
);

export const pluralizeDay = pluralize('days', 'day');

export const toTitleCase = (word: string) => {
  if (word.length === 0) return word;
  return word[0].toUpperCase() + word.slice(1);
};

export function titleCaseToKebabCase(aString: string) {
  return aString.split(' ').join('-').toLowerCase();
}

export function capitalizeEachWord(str: string) {
  return str.split(' ').map(capitalize).join(' ');
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.substring(1);
}

export const removePeriod = (sentence: string): string =>
  sentence.replace(/\.$/, '');

// cities with more than two words in it have the second word lowercased
// some cities have extra info at the end, starting with a comma (,)
export function sanitizeBusinessCity(city?: string) {
  return city ? capitalizeEachWord(city.split(',')[0]) : '';
}
