import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import { getFullStoryCurrentSessionURL } from '@src/lib/full-story';
import { getUser } from '@src/utils/cookies';
import { isFilteredException } from './non-notifiable-exceptions';
import { isFilteredRequestError } from './non-notifiable-request-errors';
import { isFilteredTransaction } from './non-notifiable-transactions';

const { SENTRY_DSN, SENTRY_APP_NAME, RELEASE_STAGE, APP_VERSION } =
  getConfig().publicRuntimeConfig;

const releaseStage = RELEASE_STAGE || 'development';
const appVersion = APP_VERSION || 'unknown';

function component() {
  return typeof window === 'undefined' ? 'nextjs-server' : 'nextjs-browser';
}

// Most of what we have here comes from
// https://blog.sentry.io/making-your-javascript-projects-less-noisy/#handling-request-errors

const nonActionableErrors = [
  "TypeError: can't access dead object",
  "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
];

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: appVersion,
    environment: releaseStage,
    tracesSampleRate: 0.05,
    normalizeDepth: 10,
    integrations:
      typeof window === 'undefined' ? [] : [Sentry.browserTracingIntegration()],
    initialScope: {
      tags: {
        // biome-ignore lint/complexity/useLiteralKeys: _
        ['trusted.app']: SENTRY_APP_NAME || 'unknown',
        // biome-ignore lint/complexity/useLiteralKeys: _
        ['trusted.component']: component(),
      },
    },
    ignoreErrors: [
      ...nonActionableErrors,
      // https://trusted-health.atlassian.net/browse/MTCH-541
      'Invariant: attempted to hard navigate to the same URL',
    ],
    ignoreTransactions: ['/_error'],
    beforeSendTransaction: (event, _hint) => {
      if (isFilteredTransaction(event)) {
        return null;
      }

      return event;
    },
    beforeSend: (event, hint) => {
      if (isFilteredRequestError(event)) {
        return null;
      }

      const error = hint.originalException;
      if (error instanceof Error && isFilteredException(error)) {
        return null;
      }

      if (!event.user) {
        event.user = {};
      }

      const cookieUser = getUser();
      if (cookieUser) {
        event.user.email = cookieUser.email;
        event.user.id = cookieUser.uuid;
      }

      // We'll be following the pattern from here:
      // https://github.com/getsentry/sentry-fullstory/blob/25aae0f6040f3e887823fc1e189a485fdbb7bdaf/src/integration.ts#L47
      const fullStoryUrl = getFullStoryCurrentSessionURL(true);
      if (fullStoryUrl) {
        event.contexts = {
          ...event.contexts,
          fullStory: { fullStoryUrl },
        };
      }
      return event;
    },
  });
};
